export enum LANG {
    EN = 'en',
    SV = 'sv',
    NO = 'no',
    DA = 'da',
    FI = 'fi',
}
export const LANGS = [LANG.EN, LANG.NO, LANG.SV, LANG.DA, LANG.FI];

//     {code: 'en', label: 'languages.en', flag: 'en'},
//     {code: 'sv', label: 'languages.sv', flag: ''},
//     {code: 'no', label: 'languages.no', flag: ''},
//     {code: 'da', label: 'languages.da', flag: ''},
//     {code: 'fi', label: 'languages.fi', flag: ''},
// ]