import { Modal } from "antd";
import { LANG } from "constants/language.const";
import React, {
  ReactChild,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import TokenApi from "services/Token";

export enum UserRole {
  ORIGINATOR_TYPE_AGENT = "ORIGINATOR_TYPE_AGENT",
  ORIGINATOR_TYPE_CUSTOMER = "ORIGINATOR_TYPE_CUSTOMER",
  ORIGINATOR_TYPE_THIRD_PARTY = "ORIGINATOR_TYPE_THIRD_PARTY",
  ORIGINATOR_TYPE_THIRD_PARTY_OTP = "ORIGINATOR_TYPE_THIRD_PARTY_OTP",
}
export interface AuthContextProps {
  isAuth: boolean;
  lang: LANG;
  hardlang: { code: any };
  setHardLang: (code: string) => void;
  logout: () => void;
  login: (data: any) => void;
  setLang: (code: LANG) => void;
  updateUser: (data: any) => void;
  user:
    | any
    | {
        username: string;
        isAdmin: boolean;
        role: UserRole;
      };
}

export const AuthContext = React.createContext({
  isAuth: false,
  lang: LANG.EN,
  hardlang: { code: null },
  setHardLang: (code: string) => {},
  logout: () => {},
  login: (data: any) => {},
  setLang: (code: LANG) => {},
  updateUser: (data: any) => {},
  user: null,
});

export const useRole = (): any => {
  const context = useContext(AuthContext);
  const user = context.user || ({} as any);
  const isAgent = user.role === UserRole.ORIGINATOR_TYPE_AGENT;
  const isThirdpartyUser = user.role === UserRole.ORIGINATOR_TYPE_THIRD_PARTY;
  const isThirdpartyOTPUser = user.isThirdpartyOTPUser;
  return { isAgent, isThirdpartyUser, isThirdpartyOTPUser, ...user };
};

export const useUser = () => {
  const context = useContext(AuthContext);
  return context.user || ({} as any);
};

export const useUpdateUser = () => {
  const context = useContext(AuthContext);
  return context.updateUser;
};

const getRoleType = (role: string): UserRole => {
  switch (role) {
    case "ADMIN":
    case "AGENT":
      return UserRole.ORIGINATOR_TYPE_AGENT;
    case "THIRDPARTY":
    case "THIRDPARTY_OTP":
      return UserRole.ORIGINATOR_TYPE_THIRD_PARTY;
    default:
      return UserRole.ORIGINATOR_TYPE_CUSTOMER;
  }
};

const AuthProvider = ({ children }: { children: ReactChild }) => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState<any>(null);
  const [isAuth] = useState<boolean>(false);
  const [lang, setCurrLang] = useState<LANG>(LANG.EN);
  const hardlang = useRef<any>({ code: "" });
  const setHardLang = useCallback((code) => {
    hardlang.current.code = code;
  }, []);
  const updateUser = useCallback((body) => {
    setUser((prev: any) => {
      return Object.assign({}, prev || {}, body);
    });
  }, []);
  const logout = useCallback(() => {
    hardlang.current.code = null;
    i18n.changeLanguage(LANG.EN);
    setCurrLang(LANG.EN);
    TokenApi.clear();
    setUser(null);
    Modal.destroyAll();
  }, [i18n]);

  const login = useCallback(({ role, workspaceId, data }: any = {}) => {
    const roleType = getRoleType(role);
    setUser(() => ({
      ...data,
      isAuth: true,
      role: roleType,
      workspaceId,
      isThirdpartyOTPUser: role === "THIRDPARTY_OTP",
    }));
    sessionStorage.removeItem("timeout");
  }, []);

  const setLang = useCallback(
    (code: LANG) => {
      const currLang = code || "en";
      i18n.changeLanguage(currLang);
      setCurrLang(currLang);
    },
    [i18n]
  );
  const context = {
    isAuth,
    lang,
    logout,
    login,
    setLang,
    hardlang: hardlang.current,
    setHardLang,
    user,
    updateUser,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
