import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

interface I18NProps {
  translateKey: string;
  params?: any;
}
const Translate = ({ translateKey, params = {} }: I18NProps) => {
  const { t } = useTranslation();
  return <Fragment>{t(translateKey, params)}</Fragment>;
};

export default Translate;
