import axios from "axios";
import queryString from "query-string";
import TokenApi from "services/Token";


const CreateClient = (baseURL: string) => {
    const url = TokenApi.getEndpoint()[baseURL];
    const client = axios.create({
        url,
        headers: {
            'content-type': 'application/json'
        },
        paramsSerializer: params => queryString.stringify(params)
    })


    client.interceptors.request.use(config => {
        const baseUrl = TokenApi.getEndpoint()[baseURL];
        //const url = `${baseUrl}/${config.url}`
        config.baseURL = baseUrl;
        const token = TokenApi.getAccessToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    })

    client.interceptors.response.use(response => {
        if (response?.data) {
            return response.data;
        }
        return response;
    }, error => {
   
        const url = error?.config?.url;
        const { code, httpCode, status } = error?.response?.data || {};
        if ((httpCode === 404 || status === 404) && url !== 'login/forget-password') {
            window.location.replace(`/404`);
            return;
        }
        if (httpCode === 401 || status === 401) {
            window.location.replace(`/logout`);
        }
        if (httpCode === 500 || status === 500) {
            // eslint-disable-next-line no-throw-literal
            throw code ? `error.${code}` : 'UNKNOWN';
        }
        // eslint-disable-next-line no-throw-literal
        throw `error.${code}`;
        // handle error
    })

    return client;
}

export default CreateClient;

