const setToken = ({ accessToken, refreshToken }: { accessToken: string, refreshToken: string }, isRemembered?: boolean) => {
    if (isRemembered) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
    } else {
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('refreshToken', refreshToken);
    }
}
const getAccessToken = () => {
    return sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');
}
const getRefreshToken = () => {
    return sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken');
}
const clear = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
}

const addWorkspace = (id: string) => {
    sessionStorage.setItem('workspaceId', id);
}
const getWorkspace = () => {
    return sessionStorage.getItem('workspaceId');
}

const setEndpoint = (setting: any) => {
    sessionStorage.setItem('setting', JSON.stringify(setting));
}
const getEndpoint = () => {
    try {
        return JSON.parse(sessionStorage.getItem('setting') || '');
    } catch (ex) {
        return {};
    }

}
const getDefaultLang = () => {
    return sessionStorage.getItem('DEFAUL_LANG');
}
const setDefaultLang = (lang: any) => {
    return sessionStorage.setItem('DEFAUL_LANG', lang);
}
// const compress = (data: string) => {
//     const salt = bcryptjs.genSaltSync(10);
//     return bcryptjs.hashSync(data, salt)
// }
const TokenApi = {
    setToken,
    getAccessToken,
    getRefreshToken,
    clear,
    addWorkspace,
    getWorkspace,
    setEndpoint,
    getEndpoint,
    getDefaultLang,
    setDefaultLang
}
export default TokenApi;