import { Button, message } from "antd";
import Translate from "components/Translate";
import { LANG } from "constants/language.const";
import useLang from "hooks/useLang";
import React, {
  ReactChild,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import TokenApi from "./Token";

export interface CaseContextProps {
  data: {
    brandColour: string;
    name: string;
    locale: string;
    currentLocale: string;
  };
  setData: (item: any) => void;
  clear: () => void;
  success: () => void;
  error: () => void;
}
const CaseContext = React.createContext({} as any);

export const useSucess = () => {
  const context = useContext(CaseContext);
  return context.success;
};
export const useError = () => {
  const context = useContext(CaseContext);
  return context.error;
};
export const useBrandColor = () => {
  const context = useContext(CaseContext);
  return context?.data;
};
export const useSetBrandColor = () => {
  const context = useContext(CaseContext);
  return context?.setData;
};
export const useCleanBrandColor = () => {
  const context = useContext(CaseContext);
  const { clear } = context;
  const { setLang, hardlang } = useLang();
  useEffect(() => {
    clear && clear();
    const defaultLang = TokenApi.getDefaultLang();
    setLang(((hardlang && hardlang.code) || defaultLang) as LANG);
  }, [clear, hardlang, setLang]);
};
const CaseProvider = ({ children }: { children: ReactChild }) => {
  const [body, setBody] = useState<any>({});

  const setData = useCallback((item: any) => {
    setBody((prev: any) => Object.assign({}, prev || {}, item));
  }, []);
  const clear = useCallback(() => {
    setBody({});
  }, []);

  const success = useCallback((mess: string, params?: any) => {
    const key = new Date().getTime();
    const clicked = () => {
      message.destroy(key);
    };
    message.success({
      content: (
        <div className="on-custom-content">
          <span>
            <Translate translateKey={mess} params={params} />
          </span>
          <Button type="text" onClick={clicked} className="close">
            <Translate translateKey="button.close-now" />
          </Button>
        </div>
      ),
      className: "on-success",
      key,
    });
  }, []);

  const error = useCallback((mess: string, params?: any) => {
    const key = new Date().getTime();
    const clicked = () => {
      message.destroy(key);
    };
    message.error({
      content: (
        <div className="on-custom-content">
          <Translate translateKey={mess} params={params} />
          <Button type="text" onClick={clicked} className="close">
            <Translate translateKey="button.close-now" />
          </Button>
        </div>
      ),
      className: "on-error",
      key,
    });
  }, []);

  const context = {
    data: body,
    setData,
    clear,
    success,
    error,
  };

  return (
    <CaseContext.Provider value={context}> {children} </CaseContext.Provider>
  );
};

export default CaseProvider;
