import AppLoading from "components/AppLoading";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserApi from "services/api/UserApi";
import { useQueryParam, StringParam } from "use-query-params";

const DocumentSigningRedirect = () => {
  const [requestId] = useQueryParam("requestId", StringParam);
  const [taskId] = useQueryParam("taskId", StringParam);
  const history = useHistory();
  // requestId
  if (!requestId || !taskId) {
    history.replace("/");
  }
  useEffect(() => {
    if (!!requestId) {
      UserApi.getSigningStatus(requestId, taskId)
        .then((res) => {})
        .finally(() => {
          history.replace("/");
        });
    }
  }, [history, requestId, taskId]);
  return <AppLoading />;
};

export default DocumentSigningRedirect;
