import { Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";


const AppLoading = () => {
  const { t } = useTranslation();
  return (
    <section
      style={{
        width: "100%",
        height: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin tip={t("app.tips.application-loading")}></Spin> 
    </section>
  );
};

export default AppLoading;
