import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LANG, LANGS } from 'constants/language.const';
import DomainConfig from 'services/domain.config';
import TokenApi from 'services/Token';


const lang = DomainConfig.getLang();
TokenApi.setDefaultLang(lang);
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: LANG.EN,
        lng: lang || LANG.EN,
        supportedLngs: LANGS,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}/translations.json'
        },
        react: {
            useSuspense: false //   <---- this will do the magic
        }
    });


export default i18n;