import { LANG } from "constants/language.const";

const CONFIG: { [key: string]: string } = {
    no: LANG.NO,
    se: LANG.SV,
    dk: LANG.DA,
    fi: LANG.FI
}
const getLang = () => {
    const hostname = window.location.hostname || '';
    let firstKey = '';
    const firstKeyCheck = hostname.slice(0, 3) as string;
    if (firstKeyCheck.endsWith("-")) {
        firstKey = firstKeyCheck.slice(0, 2);
    }
    const endKey = hostname.split('.').pop() as string;
    const lowerCaseKey = (firstKey || endKey || '').toLowerCase();
    return CONFIG[lowerCaseKey] || LANG.EN;
}

const DomainConfig = {
    getLang
}
export default DomainConfig;