import useLogout from "hooks/useLogout";
import React from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const logout = useLogout();
  const history = useHistory();

  setTimeout(() => {
    logout();
    history.replace("/login");
  }, 0);
  return <div></div>;
};

export default Logout;
