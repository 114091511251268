import { Route, Switch, useHistory } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBell, faEdit, faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faHouseUser,
  faPlus,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
  faUpload,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import React, { Fragment, useEffect, useState } from "react";
import AppLoading from "components/AppLoading";
import UserApi from "services/api/UserApi";
import useLogin from "hooks/useLogin";
import TokenApi from "services/Token";
import Logout from "pages/Login/Logout";
import SignicatAuthRedirect from "pages/Signicat/SignicatAuthRedirect";
import DocumentSigningRedirect from "pages/Signicat/DocumentSigningRedirect";

library.add(
  faBell,
  faUser,
  faHouseUser,
  faUpload,
  faEdit,
  faTrash,
  faSort,
  faSortUp,
  faSortDown,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faSearch,
  faPlus,
  faFileAlt
);

const LayoutComponent = React.lazy(() => import("components/layout/Layout"));
const LoginComponent = React.lazy(() => import("pages/Login/Login"));
const ForgetPasswordComponent = React.lazy(
  () => import("pages/Login/ForgetPassword")
);
const NotFoundPage = React.lazy(() => import("pages/Error/NotFound"));
function App() {
  const login = useLogin();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const paths = window.location.pathname.split("/workspace/");
    const id = paths[1];
    const params = new URLSearchParams(window.location.search);
    const type = params.get("bank-id-login") === "true" ? "BANKING" : "OTP";
    const isAnotherMode = params.get("anon") === "true";
    fetch(`${window.location.origin}/setting.json`)
      .then((res) => res.json())
      .then((setting) => {
        TokenApi.setEndpoint(setting);
        const token = TokenApi.getAccessToken();
        if (!!token) {
          UserApi.getUser()
            .then((res: any) => {
              if (res?.role !== "ADMIN" && res?.role !== "AGENT") {
                const workspaceId = res?.workspaceId;
                if (isAnotherMode && !!id && id !== workspaceId) {
                  TokenApi.clear();
                  window.location.replace(`/login/${id}/${type}`);
                  return;
                }
              }
              login(res);
            })
            .catch((err) => {
              TokenApi.clear();
              history.replace("/login");
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
          if (isAnotherMode) {
            window.location.replace(`/login/${id}/${type}`);
          }
        }
      });
  }, [history, login]);

  return (
    <Fragment>
      {loading ? (
        <AppLoading />
      ) : (
        <Switch>
          <Route path="/login/:id/:type" component={LoginComponent} exact />
          <Route path="/login" component={LoginComponent} exact />
          <Route path="/logout" component={Logout} exact />
          <Route
            path="/forgotpassword"
            component={ForgetPasswordComponent}
            exact
          />
          <Route
            path="/signicat-redirect"
            component={SignicatAuthRedirect}
            exact
          ></Route>
          <Route
            path="/signicat-e-sign-redirect"
            component={DocumentSigningRedirect}
            exact
          ></Route>
          <Route path="/404" component={NotFoundPage} exact />
          <Route path="*" component={LayoutComponent} />
        </Switch>
      )}
    </Fragment>
  );
}

export default App;
