import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "moment/locale/sv";
import "moment/locale/da";
import "moment/locale/fi";
import "moment/locale/nb";
import "./services/i18n/i18n";
import "./App.scss";
import App from "./App";
import AppLoading from "components/AppLoading";
import AuthProvider from "services/auth/AuthContext";
import CaseProvider from "services/CaseProvider";
import { QueryParamProvider } from "use-query-params";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<AppLoading />}>
      <BrowserRouter>
        <AuthProvider>
          <CaseProvider>
            <QueryParamProvider>
              <App />
            </QueryParamProvider>
          </CaseProvider>
        </AuthProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
