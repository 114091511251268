import AppLoading from "components/AppLoading";
import useLogin from "hooks/useLogin";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserApi from "services/api/UserApi";
import { useError } from "services/CaseProvider";
import TokenApi from "services/Token";
import { StringParam, useQueryParam } from "use-query-params";

const SignicatAuthRedirect = () => {
  const [code] = useQueryParam("code", StringParam);
  const [state] = useQueryParam("state", StringParam);
  const login = useLogin();
  const onError = useError();
  const history = useHistory();
  if (!state) {
    history.replace("/login");
  } else if (!code) {
    // login error
    onError("error.signicat-fail");
    history.replace(`/login/${state}/BANKING`);
  }
  useEffect(() => {
    if (!!code && !!state) {
      UserApi.signicatLogin(code, state, "")
        .then((res: any) => {
          TokenApi.setToken(res, false);
          TokenApi.addWorkspace(state || "");
          return UserApi.getUser();
        })
        .then((res) => {
          login(res);
          history.replace("/");
        })
        .catch((err) => {
          onError("error.signicat-fail");
          history.replace(`/login/${state}/BANKING`); // 305462cb-c88b-43a3-afd1-61fb1af99dec/BANKING
        });
    }
  }, [code, history, login, onError, state]);
  return <AppLoading />;
};

export default SignicatAuthRedirect;
