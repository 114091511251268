import AdminClient from "./AdminClient"
// import * as b from 'bcrypt';

// const hashKey = (password: string) => {
//     return b.hashSync(password, 10);
// }

const getUser = () => {
    return AdminClient.get('login/user-info');
    // return Promise.resolve({
    //     username: 'Nam',
    //     isAdmin: false,
    //     role: 'Agent'
    // })
}

const login = (body: any) => {
    return AdminClient.post('login', body);
}

const customerLogin = async (workspaceId: string, customerToken: string) => {
    return AdminClient.post('login/customer', { workspaceId, customerToken });
}

const forgotPassword = (body: any) => {
    const host = window.location.origin;
    return AdminClient.post('login/forget-password', { ...body, host })
}

const resetPassword = (body: any) => {
    return AdminClient.post('login/reset-password', body)
}
const signicatLogin = (signicatCode: any, workspaceId: any, customerToken: any) => {
    return AdminClient.post('login/customer', { signicatCode, workspaceId, customerToken });
}
const getSigningUrl = (docIds: string, locale: string, host: string) => {
    return AdminClient.get('signicat/signing-url', { params: { docIds, locale, host } })
}

const getSigningStatus = (requestId: any, taskId: any) => {
    return AdminClient.get('signicat/signing-status', { params: { requestId, taskId } })
}

const UserApi = {
    login,
    customerLogin,
    getUser,
    resetPassword,
    forgotPassword,
    signicatLogin,
    getSigningUrl,
    getSigningStatus,
    // hashKey
}
export default UserApi;